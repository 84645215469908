import * as React from "react"
import styled from "styled-components/macro"
import CarrotSvg from "../../../components/icons/Carrot"
import { fontWeight, palette, pxToRem, zIndexes } from "../../../utils/style-utils"

const PoolHeaderSelectRow = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  align-items: center;
  font-size: ${pxToRem(12)};
  font-weight: ${fontWeight.semiBold};
  color: ${palette.gray1};
  @media (max-width: ${pxToRem(450)}) {
    font-size: ${pxToRem(10)};
  }
  &[disabled] {
    pointer-events: none;
    & svg {
      display: none;
    }
  }
`
const PoolHeaderSelectLabel = styled.label`
  padding: ${pxToRem(8)};
  margin-right: ${pxToRem(8)};
  text-transform: uppercase;
  cursor: pointer;
`
// NOTE qac: you can style the options in windows
const PoolHeaderSelectOptions = styled.select`
  flex: 1;
  background: transparent;
  appearance: none;
  padding: ${pxToRem(12)} ${pxToRem(34)};
  color: ${palette.gray1};
  cursor: pointer;
  .is-alt--true & {
    color: rgba(0, 0, 0, 0);
  }
  &:focus svg {
    transform: rotate(-90deg);
  }
  & option {
    color: ${palette.gray3};
    padding: 2px 8px;
  }
`
const PoolHeaderSelectOptionsContainer = styled.label`
  position: relative;
  border: ${pxToRem(1)} solid ${palette.gray3};
  cursor: pointer;
  display: flex;
  justify-content: flex-end;
  min-width: ${pxToRem(135)};
  max-width: ${pxToRem(250)};
  height: ${pxToRem(33)};
  & svg {
    position: absolute;
    right: ${pxToRem(12)};
    top: 0;
    width: ${pxToRem(12)};
    fill: ${palette.gray1};
    transform: rotate(90deg);
    transition: transform 0.2s ease;
    pointer-events: none;
  }
`

const PoolHeaderSelectOptionsCurrent = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: ${zIndexes.ui1};
  pointer-events: none;
  margin-right: ${pxToRem(8)};
  text-transform: uppercase;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`

const onSelectSubmit = (value, update) => (event) => {
  event.preventDefault()
  const newValue = event.target.value
  if (value !== newValue) {
    update(newValue)
  }
}
export const PoolHeaderSelect = ({ value, update, options, name, label, ...rest }) => {
  if (options.length < 2) {
    return null
  }
  const curr = options.find((o) => o.value === value)
  const isAlt = label !== "View"
  return (
    <PoolHeaderSelectRow {...rest} as="form" onSubmit={onSelectSubmit(value, update)} className={`is-alt--${isAlt}`}>
      {!isAlt && (
        <PoolHeaderSelectLabel as="label" htmlFor={`${name}-phsr`}>
          {label}
        </PoolHeaderSelectLabel>
      )}
      <PoolHeaderSelectOptionsContainer>
        {isAlt && <PoolHeaderSelectOptionsCurrent>{curr && curr.label}</PoolHeaderSelectOptionsCurrent>}
        <PoolHeaderSelectOptions as="select" value={value || ""} id={`${name}-phsr`} onChange={onSelectSubmit(value, update)}>
          {options.map((option) => (
            <option value={option.value || ""} key={option.value}>
              {option.label}
            </option>
          ))}
        </PoolHeaderSelectOptions>
        <CarrotSvg />
      </PoolHeaderSelectOptionsContainer>
    </PoolHeaderSelectRow>
  )
}

export default PoolHeaderSelect
