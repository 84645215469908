/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { useMutation, useQuery, QueryResult } from "@apollo/client"
import { Field, Form, Formik, FormikErrors } from "formik"
import React, { ReactText } from "react"
import { EntryDetailsFragment } from "../../../../__generated__/EntryDetailsFragment"
import {
  PeriodEventsQuery,
  PeriodEventsQuery_gameInstance_period_eventGroups,
  PeriodEventsQuery_gameInstance_period_events_edges_node,
  PeriodEventsQueryVariables,
} from "../../../../__generated__/PeriodEventsQuery"
import { PoolPeriodQuery, PoolPeriodQuery_gameInstance_period, PoolPeriodQueryVariables } from "../../../../__generated__/PoolPeriodQuery"
import { SetPoolPeriodEventsMutation } from "../../../../__generated__/SetPoolPeriodEventsMutation"
import { extractValidationError } from "../../../../common/apiErrors"
import constants from "../../../../common/constants"
import { TCbsAccessToken } from "../../../../common/db-typings.d"
import { SpreadTypeEnum, ENUM_SCHEDULED, GamesPerPeriodEnum } from "../../../../common/enums"
import { formatSpread } from "../../../../common/misc-utils"
import { SPORT_TYPE_WITH_SECOND_EVENT_OF_THE_PERIOD_ID, TiebreakerTypesWithQuestions } from "../../../../common/pool-settings"
import { TPoolRouteProps } from "../../../../routes.d"
import { ButtonSmallBase, LinkButton } from "../../../components/Button"
import { FormButtonSpinnerBase, FormErrors, FormSuccessStatus } from "../../../components/Form"
import CheckboxSvg from "../../../components/icons/Checkbox"
import EmptyPageFillerSvg from "../../../components/icons/EmptyPageFiller"
import Link from "../../../components/Link"
import LoadingView from "../../../components/LoadingView"
import TeamImg from "../../../components/TeamImg"
import useControls from "../../../hooks/useControls"
import { extractPoolOgProps, extractPoolSettings } from "../../../hooks/usePoolData"
import NexusSelect from "../../../shared/molecules/Form/Select"
import { teamLongName, toDateTime } from "../../../utils/data-utils"
import { mapToId, toNodes, uniqueNonNull } from "../../../utils/misc-utils"
import { getParam, setParam } from "../../../utils/url-utils"

import { PERIOD_EVENTS_QUERY, SET_POOL_PERIOD_EVENTS_MUTATION } from "../../queries"
import PeriodSelect from "../components/PeriodSelect"
import { StandardPoolPageContainer } from "../components/PoolPage"
import { CtaModule, TiebreakLabel } from "../styles/Picks.styles"
import {
  ListMaxWidthContainer,
  NoSpreadFlag,
  PoolHeaderControlsSection,
  RightControlsContainer,
  SetEventsApprovedCheckbox,
  SetEventsItem,
  SetEventsItemCheckmark,
  SetEventsItemGrow,
  SetEventsItemInfo,
  SetEventsItemInput,
  SetEventsItemName,
  SetEventsItemsContainer,
  StickyTopBar,
  StickyTopBarBoxShadowProtector,
  ToggleContainer,
  ToggleContainerButton,
} from "../styles/PoolMyEntry.styles"
import { IPickUtilsGameSettingsValues } from "../../../../common/common-utils-types"
import { invalidFinalGameStatuses } from "../../../../common/common-utils-helpers"
import Code from "../../../components/Code"
import { emptyObject } from "@cbs-sports/sports-shared-client/build/cjs/utils"

type PeriodEventsQueryResult = QueryResult<PeriodEventsQuery, PeriodEventsQueryVariables>
type PoolPeriodQueryResult = QueryResult<PoolPeriodQuery, PoolPeriodQueryVariables>

interface ITabLink {
  children: string
  queryParamValue: string | null
  formAttr: string
}

interface IProps extends TPoolRouteProps, IPickUtilsGameSettingsValues {
  canEditSpread: boolean
  canUseTeamLogos: boolean
  periodEventsQuery: PeriodEventsQueryResult
  poolPeriodQuery: PoolPeriodQueryResult
  isChallengePool: boolean
  periodId: string
  poolId: string
  poolName: string
  poolUrl: string
  isBracket: boolean
  gameInstanceUid: string
  hasManagerRole: boolean
  isInComingSoon: boolean
  isManagerMode: boolean
  mutationState: any
  updateOrder: (value: number) => void
  goToPage: (cursor: TCbsAccessToken) => void
  mutate: (args: any) => Promise<any>
  period: PoolPeriodQuery_gameInstance_period | undefined
  entry: EntryDetailsFragment | undefined
}

const defaultConferences = ["SEC", "BIG12", "PAC12", "BIG10", "ACC"]
// const spreadCategories = ["Have Spreads", "No Spreads"];
const categoryQueryParamKey = "category"
const selectedTabValue = "selected"

const collegeConfMapping = [
  {
    id: "FBS",
    label: "All FBS Games",
    conferences: ["ACC", "AAC", "BIG12", "BIG10", "USA", "IA", "MAC", "MWC", "PAC12", "SEC", "BELT"],
  },
]

const teamRankMax = 9999
const conferenceAbbrevsForEvent = ({ homeTeam, awayTeam }): string[] => [homeTeam && homeTeam.conferenceAbbrev, awayTeam && awayTeam.conferenceAbbrev]

function rankForEvent(event: any) {
  const homeTeamRank = event.extra.homeTeamRank || teamRankMax
  const awayTeamRank = event.extra.awayTeamRank || teamRankMax
  const highRankedConfs = conferenceAbbrevsForEvent(event).filter((conf) => defaultConferences.includes(conf))
  return homeTeamRank + awayTeamRank - highRankedConfs.length
}

function isUnderRank(event: any, rank: number) {
  const homeTeamRank = event.extra.homeTeamRank || teamRankMax
  const awayTeamRank = event.extra.awayTeamRank || teamRankMax
  return Math.min(homeTeamRank, awayTeamRank) < rank
}

const conferenceAbbrevsFor = (events: any[]): string[] => {
  return uniqueNonNull(events.map(conferenceAbbrevsForEvent).flat())
}

function eventSorter(a: any, b: any) {
  // lower value is better
  return rankForEvent(a) - rankForEvent(b)
}

const tabQueryParam = "tab"
const eventGroupsTab = {
  children: "Select Games",
  queryParamValue: null,
  formAttr: "eventIds",
} as ITabLink
const gotpTab = {
  children: "Tiebreaker Game",
  queryParamValue: "game-of-the-period",
  formAttr: "eventOfThePeriodId",
} as ITabLink
const spreadTab = {
  children: "Spreads",
  queryParamValue: "spreads",
  formAttr: "spreads",
} as ITabLink
const challPoolLinks = [eventGroupsTab, gotpTab, spreadTab]

class PoolSetEvents extends React.PureComponent<IProps, { selectedCategory: string | null; viewingSpreadEventId: string | null }> {
  public state = {
    selectedCategory: null,
    viewingSpreadEventId: null,
  }

  public currentTab() {
    const tabQs = getParam(tabQueryParam, this.props.location.search)
    const tab = challPoolLinks.find(({ queryParamValue }) => queryParamValue === tabQs)
    if (!tab) {
      throw new Error(`invalid tab`)
    }
    return tab
  }

  public getInitialValues = () => {
    // console.log(`getInitialValues currentTab: ${this.currentTab().formAttr}`)
    // console.dir(this.props)
    // const currentTab = this.currentTab();
    const { canEditSpread, gamesPerPeriod } = this.props
    const needsGOTW = this.needsGOTW()
    const period = this.getPoolPeriod()
    const eventOfThePeriodId = (needsGOTW && period.eventOfThePeriodId) || null
    const secondEventOfThePeriodId = (needsGOTW && period.secondEventOfThePeriodId) || null
    const poolEvents = toNodes(period.events)
    const approved = !(period.needsEventGroupCategoryApproval || period.needsEventGroupApproval)
    const allEvents = this.allEvents()
    const eventIds = poolEvents.map(mapToId)
    const hasSetEventOfThePeriodId = !needsGOTW || !!eventOfThePeriodId

    if (canEditSpread) {
      const spreads = {} as any

      const getCustomSpreadValue = (value: number) => {
        return Number.isInteger(value) ? value + 0.5 : value
      }

      function getDefaultHomeTeamSpread(event?: PeriodEventsQuery_gameInstance_period_events_edges_node) {
        if (!event) {
          return ""
        }
        const homeTeamId = event.homeTeam?.cbsTeamId ?? ""
        const spreads = event.oddsMarket?.spreads ?? []
        const homeTeamSpread = spreads.find((x) => Number(x.teamId) === Number(homeTeamId))
        if (!homeTeamSpread) {
          return ""
        }
        const result = parseFloat(homeTeamSpread.spread)
        if (isNaN(result)) {
          return ""
        }
        return getCustomSpreadValue(result)
      }

      allEvents.forEach((event) => {
        if (typeof event.homeTeamSpread === "number") {
          spreads[event.id] = {
            spread: getCustomSpreadValue(event.homeTeamSpread),
            teamIdForSpread: event.homeTeam?.id,
          }
        } else {
          spreads[event.id] = {
            spread: getDefaultHomeTeamSpread(event),
            teamIdForSpread: event.homeTeam?.id,
          }
        }
      })
      return {
        category: gamesPerPeriod,
        spreads,
        eventOfThePeriodId,
        secondEventOfThePeriodId,
        eventIds,
        approved,
        hasSetEventOfThePeriodId,
      }
    } else {
      return {
        eventOfThePeriodId,
        secondEventOfThePeriodId,
        eventIds,
        approved,
        hasSetEventOfThePeriodId,
      }
    }
  }

  public onSubmit = async (values, actions) => {
    const { mutate, poolId, periodId, isBracket } = this.props
    const needsDefaultEventOfThePeriodId = this.needsGOTW() && values.eventIds.length && !values.eventOfThePeriodId
    const valuesWithDefault = Object.assign({}, values)
    if (needsDefaultEventOfThePeriodId) {
      valuesWithDefault.eventOfThePeriodId = values.eventIds[values.eventIds.length - 1]
    }
    const variables = Object.assign(
      {
        poolId,
        periodId,
        isBracket,
      },
      valuesWithDefault,
    )
    // cleanup spreads:
    if (valuesWithDefault.spreads) {
      const spreads = [] as any
      Object.keys(valuesWithDefault.spreads).forEach((key) => {
        if (
          valuesWithDefault.spreads[key] &&
          typeof valuesWithDefault.spreads[key].spread === "number" &&
          typeof valuesWithDefault.spreads[key].teamIdForSpread === "string"
        ) {
          spreads.push({
            eventId: key,
            spread: valuesWithDefault.spreads[key].spread,
            teamIdForSpread: valuesWithDefault.spreads[key].teamIdForSpread,
          })
        }
      })
      variables.spreads = spreads
    }
    if (variables.hasOwnProperty("hasSetEventOfThePeriodId")) {
      delete variables.hasSetEventOfThePeriodId
    }
    return mutate({ variables, refreshQueries: ["PeriodEventsQuery"] })
      .then((_res) => {
        // console.log('res')
        // console.dir(res)
        actions.setStatus(FormSuccessStatus)
        actions.setSubmitting(false)
        if (needsDefaultEventOfThePeriodId) {
          actions.setValues(valuesWithDefault)
        }
        if (variables.spreads) {
          this.props.periodEventsQuery.refetch().then(() => actions.resetForm())
        } else {
          setTimeout(() => {
            actions.resetForm()
          }, 900)
        }
      })
      .catch((err) => {
        // console.log('err')
        // console.dir(err)
        const apiErrors = extractValidationError(err)
        actions.setErrors(apiErrors.errors)
        actions.setSubmitting(false)
      })
  }

  public validate = (values) => {
    // console.log("validate");
    // console.dir(values);
    const errors: FormikErrors<any> = {}
    // if (!values.name) {
    //   errors.name = "Name is required";
    // }
    if (values.approved) {
      const error = this.checkApprovalErrors(values)
      if (error) {
        errors.base = error
      }
    }
    return errors
  }

  public needSecondEventOfThePeriodId = () => {
    const { isChallengePool, period } = this.props
    const {
      segment: { sportType },
    } = period || emptyObject
    const needsGOTW = this.needsGOTW()
    return needsGOTW && isChallengePool && SPORT_TYPE_WITH_SECOND_EVENT_OF_THE_PERIOD_ID.includes(sportType as any)
  }

  public checkApprovalErrors = (values: any) => {
    const needsGOTW = this.needsGOTW()
    const needSecondEventOfThePeriodId = this.needSecondEventOfThePeriodId()

    if (values.eventIds.length < constants.POOL_MIN_EVENTS_PER_PERIOD) {
      return `You must select at least ${constants.POOL_MIN_EVENTS_PER_PERIOD} game to publish`
    } else if (values.eventIds.length > constants.POOL_MAX_EVENTS_PER_PERIOD) {
      return `You can only select ${constants.POOL_MAX_EVENTS_PER_PERIOD} games`
    } else if (
      needSecondEventOfThePeriodId &&
      (!values.eventIds.includes(values.eventOfThePeriodId) || !values.eventIds.includes(values.secondEventOfThePeriodId))
    ) {
      return `You need to select 2 games for your tiebreaker to publish`
    } else if (needsGOTW && !values.eventIds.includes(values.eventOfThePeriodId)) {
      return `You need to select a game for your tiebreaker to publish`
    }
    return null
  }

  public allEvents() {
    return toNodes(this.props.periodEventsQuery.data?.gameInstance?.period?.events || undefined)
  }

  public getConferences() {
    const events = this.allEvents().concat([]).sort(eventSorter)
    return conferenceAbbrevsFor(events)
  }

  public getEventGroups() {
    return this.props.periodEventsQuery.data?.gameInstance?.period?.eventGroups || ([] as PeriodEventsQuery_gameInstance_period_eventGroups[])
  }

  public getPoolPeriod() {
    return this.props.poolPeriodQuery.data?.gameInstance.period || ({} as never)
  }

  public needsGOTW() {
    const period = this.getPoolPeriod()
    if (!period.canCustomizeEventOfThePeriod) {
      return false
    }
    const tiebreakers = [this.props.mainTiebreaker, this.props.secondaryTiebreaker]
    return !!TiebreakerTypesWithQuestions.find((t) => tiebreakers.includes(t as any))
  }

  public buildSelect(selectedEventIds?: ReactText[]) {
    const { isChallengePool, poolPeriodQuery, location, canEditSpread } = this.props
    const { search } = location
    let currentValue = getParam(categoryQueryParamKey, search)
    const currentTab = this.currentTab()
    const isGOTWTab = currentTab === gotpTab
    const isSpreadsTab = currentTab === spreadTab
    const period = poolPeriodQuery.data?.gameInstance.period || ({} as never)
    // const eventOfThePeriodId = period.eventOfThePeriodId || null;
    const poolEvents = toNodes(period.events)
    const allEvents = this.allEvents()
    const eventIds = selectedEventIds || poolEvents.map(mapToId)
    const conferences = this.getConferences()
    const eventGroups = this.getEventGroups()
    const eventsUnderRank = allEvents.filter((evt) => isUnderRank(evt, 25))
    // const eventGroupCategories = uniqueNonNull(eventGroups.map(({category}) => category));
    // const eventGroupIds = uniqueNonNull([...eventGroups.map((eg) => eg.eventIds)]);
    const buildOption = (value: string, label: string) => ({
      id: value,
      label,
      value,
    })
    const canDisplayAllEvents = allEvents.length < 50
    // START: build options
    const options = [buildOption("all", "All")]
    if (isGOTWTab) {
      // remove all, leave Selected (dropdown hidden if 1 option)
      options.shift()
      options.push(buildOption(selectedTabValue, "Selected Games"))
    } else if (!canDisplayAllEvents) {
      // remove the all
      const allOption = options.shift()!
      if (!isChallengePool) {
        eventGroups.forEach(({ category, id }) => {
          options.push(buildOption(id, category))
        })
      }
      if (eventsUnderRank.length) {
        options.push(buildOption("top-25", "Top 25"))
      }
      collegeConfMapping.forEach((cat) => {
        options.push(buildOption(cat.id, cat.label))
      })
      options.push(buildOption(selectedTabValue, "Selected Games"))
      conferences.forEach((conf) => {
        options.push(buildOption(conf, conf.toUpperCase().replace("BIG10", "Big Ten")))
      })
      options.push(allOption)
    } else {
      // just show all events (no sub categories)
    }
    if (!currentValue) {
      currentValue = options[0]!.id // options.length > 1 ? options[1]!.id : options[0]!.id;
    }
    if (isSpreadsTab && !canDisplayAllEvents) {
      options.unshift(buildOption("no-spread", "No Spread"))
      options.unshift(buildOption("has-spread", "Has Spread"))
    }
    // END: build options
    // START: build visibleEventIds
    let visibleEventIds = [] as ReactText[]
    if (currentValue === "all") {
      visibleEventIds = allEvents.map(mapToId) as string[]
    } else if (currentValue === selectedTabValue) {
      visibleEventIds = eventIds as string[]
    } else {
      // lets figure this out...
      const cat = eventGroups.find(({ id }) => id === currentValue)
      const collegeConfGroup = collegeConfMapping.find(({ id }) => id === currentValue)
      const conference = conferences.find((conf) => conf === currentValue)
      if (cat) {
        // console.log(`is eventGroup:`, cat)
        visibleEventIds = uniqueNonNull(cat.eventIds) as string[]
      } else if (collegeConfGroup) {
        // console.log(`is collegeConfGroup:`, collegeConfGroup)
        visibleEventIds = allEvents
          .filter(
            (evt) =>
              !!conferenceAbbrevsForEvent(evt).find((conf) => {
                return collegeConfGroup.conferences.includes(conf)
              }),
          )
          .map(mapToId)
      } else if (conference) {
        // console.log(`is conference: `, conference)
        visibleEventIds = allEvents.filter((evt) => conferenceAbbrevsForEvent(evt).includes(conference)).map(mapToId)
      } else if (/-spread/.test(currentValue!.toString())) {
        // console.log(`is spread: ${currentValue}`)
        visibleEventIds = allEvents
          .filter((evt) => {
            const hasSpread = typeof evt.homeTeamSpread === "number"
            return currentValue === "no-spread" ? !hasSpread : hasSpread
          })
          .map(mapToId)
      } else if (currentValue === "top-25") {
        visibleEventIds = eventsUnderRank.map(mapToId)
      } else {
        // console.log(`is other: ${currentValue}`)
      }
    }
    if (!canEditSpread && currentValue !== selectedTabValue) {
      // exclude events that:
      // NOTE qac: we can  do this, but it gets confusing to managers, so by default display everything, and just use markings
      // - dont have a spread (if this is a spread pool)
      // - are already started
      visibleEventIds = allEvents
        .filter((evt) => {
          const isIncluded = visibleEventIds.includes(evt.id)
          // NOTE qac: we now display the unpickable spread events, but dont allow them to choose em
          const allowedSpread = true // !!mode || spreadType !== SpreadTypeEnum.CBS_SPREAD || typeof(evt.homeTeamSpread) === "number";
          const isNotStarted = true // !!isManagerMode || evt.startsAt >= Date.now()
          return isIncluded && allowedSpread && isNotStarted
        })
        .map(mapToId)
    }
    // END: build visibleEventIds

    const field = {
      name: categoryQueryParamKey,
      onChange: this.onSelectChange,
      value: currentValue,
    }
    const fakeForm = {
      setFieldValue: this.setFieldValue,
    }
    return {
      field,
      form: fakeForm,
      options,
      visibleEventIds,
      currentTab,
    }
  }

  public setFieldValue = (fieldName: string, value: any) => {
    // console.log(`setFieldValue: ${value}`);
    const { history, location } = this.props
    const existingSearchParts = (location.search || "").replace("?", "")
    history.push({
      ...location,
      ...{
        search: `${setParam(existingSearchParts, categoryQueryParamKey, value)}`,
      },
    })
  }

  public onSelectChange = (event) => {
    this.setFieldValue(event.target.name, event.target.value)
  }

  public toggleViewingSpreadEventId = (eventId: string) => {
    this.setState({ viewingSpreadEventId: eventId === this.state.viewingSpreadEventId ? null : eventId })
  }

  public render() {
    const {
      periodEventsQuery,
      poolPeriodQuery,
      isChallengePool,
      updateOrder,
      period,
      isInComingSoon,
      spreadType,
      canUseTeamLogos,
      canEditSpread,
      gamesPerPeriod,
      ...rest
    } = this.props
    const location = rest.location
    const { viewingSpreadEventId } = this.state
    // console.log(`PoolSetEvents props:`)
    // console.dir(period)
    const order = period?.order
    const isInComingSoonBlocked = isInComingSoon && !canEditSpread
    const isSpreadManagerPool = !isChallengePool && spreadType === SpreadTypeEnum.CBS_SPREAD
    const isManagerPoolNeedsSpreadsRelease = isSpreadManagerPool && period && period.needsEventGroupCategoryApproval && !canEditSpread

    return (
      <StandardPoolPageContainer
        {...rest}
        analyticsFeature="options"
        analyticsSubfeature={`set games`}
        analyticsTitle={`Settings - Set Games`}
        select={(!isInComingSoonBlocked && <PeriodSelect order={order} updateOrder={updateOrder} period={period} />) || null}
      >
        {(!isInComingSoonBlocked &&
          !isManagerPoolNeedsSpreadsRelease &&
          periodEventsQuery &&
          !periodEventsQuery.loading &&
          poolPeriodQuery &&
          !poolPeriodQuery.loading && (
            <Formik initialValues={this.getInitialValues()} onSubmit={this.onSubmit} validate={this.validate} enableReinitialize>
              {(formikBag) => {
                // console.log("formikBag");
                // console.dir(formikBag);
                const { isSubmitting, status, isValid, values, setValues, errors, dirty, resetForm } = formikBag
                // console.dir(values)
                const { visibleEventIds, currentTab, ...categorySelectProps } = this.buildSelect(values.eventIds)
                const { hasSetEventOfThePeriodId, eventOfThePeriodId, secondEventOfThePeriodId } = values
                const tab = currentTab
                const isSpreadsTab = tab === spreadTab
                // const currentCategory = categorySelectProps.field.value;
                const isGOTWTab = tab === gotpTab
                // const isGamePickTab = tab === eventGroupsTab;
                const needsGOTW = this.needsGOTW()
                const isSelectEventTab = tab === eventGroupsTab
                const links = [eventGroupsTab]
                if (needsGOTW) {
                  links.push(gotpTab)
                }
                if (canEditSpread) {
                  links.push(spreadTab)
                }
                const allEvents = this.allEvents()
                const selectedEventIds = (values.eventIds || []) as string[]
                const pickedEvents = allEvents.filter(({ id }) => !!selectedEventIds.includes(id))
                // console.dir(unpickedEvents)
                // console.dir(allEvents)
                const listedEvents = isGOTWTab
                  ? pickedEvents
                  : allEvents.filter((evt: any) => {
                      // const isInSelectedEvents = selectedEventIds.includes(evt.id);
                      // const isInSelectedConferences = !!conferenceAbbrevsForEvent(evt).find((conf) => selectedConferences.includes(conf));
                      // const hasSpread = typeof(evt.homeTeamSpread) === "number";
                      // const inSpreadSelection = (selectedChips.includes(spreadCategories[0]) && hasSpread) || (selectedChips.includes(spreadCategories[1]) && !hasSpread);
                      // return isInSelectedEvents || isInSelectedConferences || inSpreadSelection;
                      return visibleEventIds.includes(evt.id)
                    })

                return (
                  <Form action="#">
                    <StickyTopBar>
                      <ListMaxWidthContainer>
                        <PoolHeaderControlsSection className={`is-dirty--${!!dirty}`}>
                          {links.length > 1 && (
                            <ToggleContainer className="hide-mobile-if-dirty">
                              {links.map(({ children, queryParamValue }) => (
                                <ToggleContainerButton
                                  as={Link}
                                  isActive={tab.queryParamValue === queryParamValue}
                                  to={Object.assign({}, location, {
                                    search: setParam(location.search, tabQueryParam, queryParamValue),
                                  })}
                                  key={queryParamValue || "base"}
                                >
                                  {children}
                                </ToggleContainerButton>
                              ))}
                            </ToggleContainer>
                          )}
                          {categorySelectProps.options.length > 1 ? <NexusSelect {...categorySelectProps} /> : null}
                          <RightControlsContainer>
                            <SetEventsApprovedCheckbox as="div" className="hide-mobile-if-clean">
                              <span>{(values.eventIds || []).length} Games</span>
                              <div>Selected</div>
                            </SetEventsApprovedCheckbox>
                            <SetEventsApprovedCheckbox className={`buttonish is-valid--${!this.checkApprovalErrors(values)}`}>
                              <Field name="approved" type="checkbox" />
                              <CheckboxSvg checked={values.approved} />
                              <div>Publish</div>
                            </SetEventsApprovedCheckbox>
                            <FormButtonSpinnerBase
                              as={ButtonSmallBase}
                              success="Saved!"
                              type="submit"
                              inert="Save"
                              status={status}
                              isSubmitting={isSubmitting}
                              isValid={isValid}
                              className="hide-mobile-if-clean"
                            />
                            <LinkButton
                              as="button"
                              type="button"
                              onClick={() => {
                                resetForm()
                              }}
                              disabled={!dirty}
                              className="hide-mobile-if-clean"
                            >
                              Reset
                            </LinkButton>
                          </RightControlsContainer>
                        </PoolHeaderControlsSection>
                        <FormErrors submitCount={1} errors={errors && (errors as any).base && errors} />
                      </ListMaxWidthContainer>
                    </StickyTopBar>
                    <StickyTopBarBoxShadowProtector />
                    <SetEventsItemsContainer>
                      {listedEvents.length === 0 && (
                        <CtaModule iconSvg={<EmptyPageFillerSvg sportType={period!.segment.sportType} />}>
                          {isGOTWTab ? `You must select a game to set a Tiebreaker Game (as your tiebreaker game)` : `No games for this filter`}
                        </CtaModule>
                      )}
                      {listedEvents.map((event, index) => {
                        const { id, homeTeam, awayTeam } = event
                        const indexOfSelected = selectedEventIds.indexOf(id)
                        // const formatTheSpread = !isSpreadsTab;
                        const spread = formatSpread(event.homeTeamSpread)
                        const isDisabledSelectEventsTab = isSelectEventTab && gamesPerPeriod === GamesPerPeriodEnum.ALL
                        const isDisabledDueToSpread = isSelectEventTab && isSpreadManagerPool && !canEditSpread && !spread
                        const disabled = period?.pickingDisabledEventIds?.includes(id) || isDisabledDueToSpread || isDisabledSelectEventsTab
                        const isSelected =
                          !isSpreadsTab && (isGOTWTab ? eventOfThePeriodId === id || secondEventOfThePeriodId === id : indexOfSelected > -1)

                        const showLabel = isGOTWTab && this.needSecondEventOfThePeriodId()
                        const labelText = eventOfThePeriodId === id ? "Tiebreaker 1" : secondEventOfThePeriodId === id ? "Tiebreaker 2" : ""

                        if (!awayTeam || !homeTeam) {
                          return null
                        }
                        const awayTeamRank = event.extra.awayTeamRank && <b>{event.extra.awayTeamRank}&nbsp;</b>
                        const homeTeamRank = event.extra.homeTeamRank && <b>{event.extra.homeTeamRank}&nbsp;</b>
                        const onClick = (_evt) => {
                          _evt.preventDefault()
                          const newValues = {} as any
                          if (isSelected) {
                            const validIds = values.eventIds.filter((eId) => eId !== id)
                            newValues.eventIds = validIds
                            if (!this.needSecondEventOfThePeriodId()) {
                              const eventIdChoices = allEvents.filter((e) => validIds.includes(e.id))
                              const bestEOTPId = (eventIdChoices.length && eventIdChoices[eventIdChoices.length - 1].id) || null
                              if (eventOfThePeriodId === id || !eventOfThePeriodId) {
                                newValues.eventOfThePeriodId = bestEOTPId
                              }
                            } else {
                              if (values.eventOfThePeriodId === id) {
                                newValues.eventOfThePeriodId = undefined
                              }
                              if (values.secondEventOfThePeriodId === id) {
                                newValues.secondEventOfThePeriodId = undefined
                              }
                            }
                            setValues(Object.assign({}, values, newValues))
                          } else {
                            const validIds = [id].concat(values.eventIds as string[])
                            newValues.eventIds = validIds
                            if (!this.needSecondEventOfThePeriodId()) {
                              const eventIdChoices = allEvents.filter((e) => validIds.includes(e.id))
                              const bestEOTPId = (eventIdChoices.length && eventIdChoices[eventIdChoices.length - 1].id) || null
                              if (!hasSetEventOfThePeriodId && eventOfThePeriodId !== bestEOTPId) {
                                newValues.eventOfThePeriodId = bestEOTPId
                              }
                            }
                          }
                          if (Object.keys(newValues).length) {
                            setValues(Object.assign({}, values, newValues))
                          }
                        }
                        const onTiebreakClick = (_evt) => {
                          _evt.preventDefault()
                          // multiple tb
                          if (this.needSecondEventOfThePeriodId()) {
                            const isEventOfThePeriodId = eventOfThePeriodId === id
                            const isSecondEventOfThePeriodId = secondEventOfThePeriodId === id
                            const newValues = {} as any
                            // Clicking a selected Event
                            if (isEventOfThePeriodId || isSecondEventOfThePeriodId) {
                              if (isSecondEventOfThePeriodId) {
                                newValues.secondEventOfThePeriodId = null
                              } else {
                                newValues.eventOfThePeriodId = null
                              }
                            } else {
                              if (!eventOfThePeriodId) {
                                newValues.eventOfThePeriodId = id
                              } else if (!secondEventOfThePeriodId) {
                                newValues.secondEventOfThePeriodId = id
                              }
                            }
                            if (Object.keys(newValues).length) {
                              setValues(Object.assign({}, values, newValues))
                            }
                          } else {
                            const isSelected = eventOfThePeriodId === id
                            const newValues = {} as any
                            // Clicking a selected Event
                            if (!isSelected) {
                              newValues.eventOfThePeriodId = id
                              if (Object.keys(newValues).length) {
                                setValues(Object.assign({}, values, newValues))
                              }
                            }
                          }
                        }
                        const title = isSpreadsTab ? undefined : `${isSelected ? "Add" : "Remove"} ${awayTeam.abbrev} @ ${homeTeam.abbrev}`
                        const attrs = isSpreadsTab
                          ? { style: { paddingLeft: "0.5rem" } }
                          : {
                              type: "button",
                              as: "button" as any,
                              disabled,
                              onClick: isGOTWTab ? onTiebreakClick : onClick,
                              title,
                            }
                        return (
                          <React.Fragment key={event.id}>
                            <SetEventsItem {...attrs} className={`is-selected--${isSelected}`}>
                              {isSpreadsTab ? null : (
                                <SetEventsItemCheckmark>
                                  <CheckboxSvg checked={isSelected} />
                                </SetEventsItemCheckmark>
                              )}
                              <SetEventsItemGrow>
                                <SetEventsItemName>
                                  <TeamImg team={awayTeam} canUseTeamLogos={canUseTeamLogos} />
                                  <span>
                                    {awayTeamRank}
                                    {teamLongName(awayTeam, canUseTeamLogos)}
                                  </span>
                                  <small>{awayTeam.abbrev}</small>
                                  <i>@</i>
                                  <TeamImg team={homeTeam} canUseTeamLogos={canUseTeamLogos} />
                                  <span>
                                    {homeTeamRank}
                                    {teamLongName(homeTeam, canUseTeamLogos)}
                                  </span>
                                  <small>{homeTeam.abbrev}</small>
                                  {(event.extra.gameTitle && <u>{event.extra.gameTitle}</u>) || null}
                                  {(isSpreadsTab && (
                                    <>
                                      <SetEventsItemInput>
                                        <Field type="number" placeholder="-" step="0.5" name={`spreads[${event.id}].spread`} />
                                      </SetEventsItemInput>
                                      {(spread && <b>({spread})</b>) || (
                                        <NoSpreadFlag title={`Spread not yet available for this game.`}>No Spread</NoSpreadFlag>
                                      )}
                                      <LinkButton type="button" onClick={() => this.toggleViewingSpreadEventId(event.id)}>
                                        Show Spread Data
                                      </LinkButton>
                                    </>
                                  )) ||
                                    (spread && <b>({spread})</b>) || (
                                      <NoSpreadFlag title={`Spread not yet available for this game.`}>No Spread</NoSpreadFlag>
                                    )}
                                  {showLabel && labelText && <TiebreakLabel>{labelText}</TiebreakLabel>}
                                </SetEventsItemName>

                                <SetEventsItemInfo>
                                  {(event.gameStatusDesc === ENUM_SCHEDULED && (
                                    <>
                                      <small>
                                        {toDateTime(event.startsAt).toFormat(`EEE, L/dd @ h:mma`)}
                                        &nbsp;&nbsp;
                                      </small>
                                      <strong>{uniqueNonNull([awayTeam.conferenceAbbrev, homeTeam.conferenceAbbrev]).join(" - ")}</strong>
                                    </>
                                  )) ||
                                    (invalidFinalGameStatuses.includes(event.gameStatusDesc || "") && (
                                      <NoSpreadFlag>{event.gameStatusDesc}</NoSpreadFlag>
                                    )) || <strong>{event.gameStatusDesc}</strong>}
                                </SetEventsItemInfo>
                              </SetEventsItemGrow>
                            </SetEventsItem>
                            {(viewingSpreadEventId === event.id && isSpreadsTab && (
                              <div>
                                <details>
                                  <summary>
                                    <code>oddsMarket:</code>
                                  </summary>
                                  <Code>{JSON.stringify(event.oddsMarket, null, 2)}</Code>
                                </details>
                              </div>
                            )) ||
                              null}
                          </React.Fragment>
                        )
                      })}
                    </SetEventsItemsContainer>
                  </Form>
                )
              }}
            </Formik>
          )) ||
          (isInComingSoonBlocked && (
            <CtaModule iconSvg={<EmptyPageFillerSvg sportType={period!.segment.sportType} />}>
              You will be able to select games for your pool soon!
            </CtaModule>
          )) ||
          (isManagerPoolNeedsSpreadsRelease && (
            <CtaModule iconSvg={<EmptyPageFillerSvg sportType={period!.segment.sportType} />}>
              You will be able to set your games once CBS selected games and spreads are available.
            </CtaModule>
          )) || <LoadingView color="rgba(0,0,0,0.6)" />}
      </StandardPoolPageContainer>
    )
  }
}

function PoolSetEventsWrapper(props: TPoolRouteProps) {
  const { poolData } = props
  const {
    isBracket,
    gameInstanceUid,
    poolId,
    detailedEntry,
    hasManagerRole,
    isManagerModeActive,
    poolPeriodQuery,
    poolDetail,
    detailedPeriod,
    isInComingSoon,
    isChallengePool,
    currentUser,
    variableParams,
  } = poolData
  const periodId =
    (variableParams.order
      ? detailedPeriod?.segment.periods.edges.find((edge) => edge.node.order === variableParams.order)?.node.id
      : detailedPeriod?.id || poolData.periodId) || ""
  const controls = useControls(props)
  const mergedPoolSettings = extractPoolSettings(poolData)
  const canUseTeamLogos = !!poolDetail && poolDetail.canUseTeamLogos
  const canEditSpread = currentUser.isCbsAdmin && mergedPoolSettings?.spreadType === SpreadTypeEnum.CBS_SPREAD
  const ogProps = extractPoolOgProps(poolData)
  const [mutate, mutationState] = useMutation<SetPoolPeriodEventsMutation>(SET_POOL_PERIOD_EVENTS_MUTATION)
  const skip = !periodId
  const variables = { gameInstanceUid, periodId, poolId }
  const periodEventsQuery = useQuery<PeriodEventsQuery, PeriodEventsQueryVariables>(PERIOD_EVENTS_QUERY, {
    skip,
    variables,
  })
  if (mergedPoolSettings) {
    return (
      <PoolSetEvents
        {...props}
        entry={detailedEntry}
        canUseTeamLogos={canUseTeamLogos}
        periodId={periodId}
        poolId={poolId}
        isBracket={isBracket}
        isChallengePool={isChallengePool}
        hasManagerRole={hasManagerRole}
        poolPeriodQuery={poolPeriodQuery}
        periodEventsQuery={periodEventsQuery}
        mutate={mutate}
        period={detailedPeriod}
        mutationState={mutationState}
        isInComingSoon={isInComingSoon}
        canEditSpread={canEditSpread}
        isManagerMode={isManagerModeActive}
        {...ogProps}
        {...controls}
        {...mergedPoolSettings}
      />
    )
  } else {
    return <LoadingView />
  }
}

export default PoolSetEventsWrapper
