import * as React from "react"
import styled from "styled-components/macro"

const SVG = styled.svg`
  & path {
    transition: all 0.2s ease-out;
    transform-origin: 50%;
    transform-box: fill-box;
    transform: scale(1);

    &.checked--false {
      transform: scale(0);
    }
  }

  & rect {
    transition: all 0.2s ease-out;
  }
`

const CheckboxSvg = ({ color = "#2287E6", checked = false, backgroundColor = "#DFE2E6" }) => (
  <SVG className={`checkbox checkbox--${!!checked}`} viewBox="0 0 20 20" fill="none">
    <rect width="20" height="20" fill={backgroundColor} rx="2" />
    <path className={`checked--${!!checked}`} stroke={color} strokeLinecap="round" strokeWidth="2" d="M5.5 11.5L8 14l6.5-6.5" />
  </SVG>
)

export default CheckboxSvg
