import * as React from "react"
import { sortByOrder } from "../../../../common/sorters"
import { edgeToNode } from "../../../utils/misc-utils"
import PoolHeaderSelect from "./PoolHeaderSelect"

const mapToSelectFromPeriod = (per) => ({ value: per.order, label: per.description })

export const PeriodSelect = ({ period, updateOrder, order, label = null, ...rest }) => {
  const options = ((period && period.segment.periods.edges) || []).map(edgeToNode).sort(sortByOrder).map(mapToSelectFromPeriod)
  return <PoolHeaderSelect value={order} update={updateOrder} label={label} name="order" options={options} {...rest} />
}
export default PeriodSelect
